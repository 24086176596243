import styled from 'styled-components';
import mq from "../media-queries"

export const AlignCenter = styled.div`
  position: relative;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;

  h2, h3, p {
    text-align: center;
    color: var(--darkgrey);
  }

  a {
    position: relative;
    transition: all .25s linear;

    span {
      font-size: 2rem;
      font-weight: normal;
      color: var(--mainColor);
      -webkit-font-smoothing: subpixel-antialiased;
    }
      &:after {
        transition: all .2s linear;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        content: '.';
        color: transparent;
        background: var(--mainColor);
        height: 1px;
        top: calc(100% + 1.15em);
      }
      &:hover:after {
        width: 100%;
      }
    }
  }
`

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.padding ? props.padding : "0"};
  margin: 0 auto;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: ${props => props.justifyContent ? props.justifyContent : "normal"};
  flex-direction: column;
  ${mq.lg} {
    flex-wrap: wrap;
    flex-direction: row;
    width: ${props => props.fullWidth ? "100%" : "85%"};
  }
`

export const Item = styled.div`
  flex: 1 0 ${props => props.size ? props.size : "50%"};
  padding: ${props => props.padding ? props.padding : "15px 20px 30px"};

  ${mq.lg} {
    padding: ${props => props.padding ? props.padding : "15px 30px 30px"};

    ${props => props.isImage && `
      padding: 0;
    `}
  }
  ${props => props.isImage && `
    padding: 0;
  `}
`

export const IconWrapper = styled.div`
  margin-right: 1.5rem;
  color: var(--darkgrey);
  margin-bottom: 1rem;
`

export const Divider = styled.div`
  position: relative;
	margin-top: 20px;
  margin-bottom: 30px;
	height: 1px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 40%;
    right: 40%;
    width: 20%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(149, 27, 129), transparent);
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -8px;
    left: calc(50% - 9px);
    width: 15px;
    height: 15px;
    background-color: rgb(218, 218, 218);
    border: 1px solid rgb(149, 27, 129);
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px white,
            0 0 0 4px white;
  }
`

export const BreadcrumbWrapper = styled.div`
  display: none;
  ${mq.md} {
    display: block;
  }
  nav {
    margin: 1.5rem 0;
    .breadcrumb__list {
      list-style-type: none;
      display: flex;
      margin: 0;
      padding: 0;

      .breadcrumb__separator {
        font-size: 1.5rem;
        padding: 0 5px;
        color: var(--mainColor);
      }

      .breadcrumb__list__item {
        padding-right: 0 5px;

        .breadcrumb__link {
          color: var(--mainColor);

          &:hover {
            text-decoration: underline;
          }
        }
        .breadcrumb__link__active {
          color: var(--mainColor);
          font-weight: bold;
        }
      }
    }
  }
`

export const H1 = styled.h1`
  color: var(--mainColor);
  text-transform:uppercase;
  font-weight: bold;
  margin-bottom: 0rem;
  text-align: ${props => props.center ? "center" : "left"};

  ${mq.md} {

  }
`

export const H2 = styled.h2`
  color: var(--mainColor);
  font-size: 1.8rem;
  text-transform:uppercase;
  font-weight: bold;
  margin-bottom: 0rem;
  text-align: ${props => props.center ? "center" : "left"};

  ${mq.md} {

  }
`
export const H3 = styled.h3`
  color: var(--mainColor);
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0rem;
  text-align: ${props => props.center ? "center" : "left"};

  ${mq.md} {

  }
`

export const H1Box = styled.h1`
  font-family: 'ImpactLabelReversed',sans-serif;
  font-size: 2rem;
  background: var(--white);
  padding: 1rem;
  margin: 2rem auto 3rem;
  -webkit-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  transform: rotate(-2deg);
  text-align: center;
  color: var(--black);
  width: fit-content;
  ${mq.md} {
    font-size: 2.7rem;
  }
`
export const H1BoxBlack = styled(H1Box)`
  background: var(--black);
  color: var(--white);
`

export const Button = styled.button`
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: 0.25s;

  width: 100%;
  margin-bottom: 30px;

  &:hover,
  &:focus {
    color:var(--black);
    box-shadow: inset 0 -3.25em 0 0 var(--white);
  }
  &:last-child {
    margin:0;
  }
  ${mq.md} {
    width: fit-content;
    margin-right: 30px;
    margin-bottom: 0;
  }
`;

export const ButtonBlack = styled(Button)`
  padding: 10px 16px;

  font-family: 'Lato-Bold';
  font-size: 1.6rem;
  line-height: 1.3333333;
  color: var(--black);
  text-transform: uppercase;

  background: transparent;
  border-width: 2px;
  border-color: var(--black);
  border-radius:0;

  &:hover,
  &:focus {
    color:var(--white);
    box-shadow: inset 0 -3.25em 0 0 var(--black);
  }
`;

export const ButtonWhite = styled(Button)`
  padding: 10px 16px;

  font-family: 'Lato-Bold';
  font-size: 1.6rem;
  line-height: 1.3333333;
  color: var(--white);
  text-transform: uppercase;

  background: transparent;
  border-width: 2px;
  border-color: var(--white);
  border-radius:0;

  &:hover,
  &:focus {
    color:var(--black);
    box-shadow: inset 0 -3.25em 0 0 var(--white);
  }
`;
