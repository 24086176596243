// Breakpoints
export const breakpoints = {
  bpSmall: '48em', // 768px
  bpMedium: '64em', // 1024px
  bpLarge: '85.375em', // 1366px
  bpXlarge: '120em', // 1920px
  bpXxlarge: '160em', // 2560px
};
export const baseSizes = {
  // Font-Size
  'base-font-size': '1rem', // Font Size
  'base-line-height': '1.5', // Line-Height
  padding: '1rem',
};
export const color = {
  foreground: '#333',
  background: '#FFF',
  link: '#44F',
};
