import React from 'react';
import { useStaticQuery, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { breakpoints } from '../styles/Variables.js';

import BackgroundSection from './BackgroundSection';
import { Button, ButtonBlack,ButtonWhite, H1Box, H1BoxBlack } from '../styles/common/Element.js';
import { Container } from '../styles/common/Layout.js';

const { bpMedium, bpLarge, bpXlarge } = breakpoints;

const SectionStyles = styled.section`
  background-color: var(--darkgrey);
  color: var(--white);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const ContainerCenter = styled(Container)`
  text-align: center;

  p {
    text-align: center;
  }
`;

export default function SectionDonation() {
  const data = useStaticQuery(graphql`
    query DonationsBgQuery {
      bg: sanityStoreSettings {
        donationsBg {
          asset {
            fluid {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <SectionStyles>
        <BackgroundSection
          imageData={data.bg.donationsBg.asset.fluid}
          backgroundColor="#040e18"
        >
          <ContainerCenter>
            <H1Box>Wir wollen den Existenzen ein Gesicht geben.</H1Box>
            <p>
              Du unterstützt damit alle, die für KulturgesichterNRW ihre Zeit
              und Arbeitskraft investieren.
              <br />
              Davon werden die laufenden Kosten z.b. Fotoshootings,
              Serverkosten, etc. gedeckt.
            </p>
            <ButtonWhite as="a"
              href="http://paypal.me/kulturgesichternrw"
              target=" _blank"
              title="Paypal"
            >
              Paypal Funding
            </ButtonWhite>
            <ButtonWhite as="a"
              href="https://support.kulturgesichternrw.de/"
              target=" _blank"
              title="Merchandise"
            >
              Merchandise
            </ButtonWhite>
          </ContainerCenter>
        </BackgroundSection>
      </SectionStyles>
    </>
  );
}
