import { createGlobalStyle } from 'styled-components';

import fontRegular from '../assets/fonts/Lato-Regular.ttf';
import fontBold from '../assets/fonts/Lato-Bold.ttf';
import fontThin from '../assets/fonts/Lato-Thin.ttf';
import ImpactLabelReversed from '../assets/fonts/ImpactLabelReversed.ttf';

const Typography = createGlobalStyle`
   @font-face {
    font-family: 'Lato-Thin';
    src: url(${fontThin});
    font-weight: 300;
  }
  @font-face {
    font-family: 'Lato-Regular';
    src: url(${fontRegular});
    font-weight: 400;
  }
  @font-face {
    font-family: 'Lato-Bold';
    src: url(${fontBold});
    font-weight: 700;
  }

  @font-face {
    font-family: 'ImpactLabelReversed';
    src: url(${ImpactLabelReversed});
    font-weight: 400;
  }

  html {
    font-family: 'Lato-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--white);
  }
  body {
    line-height: 1.65;
  }
  p, li {
    letter-spacing: 0.5px;
    font-size: 1.6rem;

  }
  p {
    margin-top: 0;
    margin-bottom: 1.15rem;
    text-align: justify;
    text-justify: auto;
    line-height: 1.4;
    word-break: keep-all;
  }

h1, h2, h3, h4, h5 {
  margin: 2.75rem 0 1.05rem;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 1.15;
}

h1 {
  margin-top: 0;
  font-size: 3.052em;
}

h2 {font-size: 2.441em;}

h3 {font-size: 1.953em; text-decoration:underline}

h4 {font-size: 1.563em;font-family: 'Lato-Bold', sans-serif;}

h5 {font-size: 1.25em;}
  a {
    color: var(--white);
    text-decoration:none;
    /* text-decoration-color: var(--red); */
    /* Chrome renders this weird with this font, so we turn it off */
    /* text-decoration-skip-ink: none; */
  }
  mark, .mark {
    background: var(--black);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }
  .center {
    text-align: center;
  }
  .tilt {
    transform: rotate(-2deg);
  }
`;

export default Typography;
