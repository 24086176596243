import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Logo from './Logo';
import { FaInstagram, FaFacebookSquare } from "react-icons/fa";
import { Container } from '../../styles/common/Layout';
import mq from '../../styles/media-queries';

const NavWrapper = styled.div`
  background-color: var(--black);
  width:100%;
`

const NavBrand = styled.div`
  flex: 0 0 150px;
  align-self: center;

  ${mq.sm} {
    width: 300px;
    align-self: start;
    max-width: inherit;
  }
`
const NavBar = styled.div`
  display: flex;
  width:100%;

  ul {
    width:100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    text-align: center;
    list-style: none;
    transition: height 0.25s linear;
    flex-direction: column;
    li {
      flex: 1 0 15%;
      ${mq.sm} {
        margin-bottom: 1rem;
      }
    }

    ${mq.md} {
      flex-direction: row;
    }
  }

  li {
    &.social {
      flex: 0 1 15%;
      text-align: right;
      a:first-child {
        margin-right: 1rem;
      }
    }
  }
`

const NavStyles = styled(Container)`
  display:flex;
  justify-content:space-between;
  margin-bottom: 3rem;
  padding-top: 1rem;
  flex-direction: column;

  ${mq.sm} {
    flex-direction: row;
  }
`;

const LinkStyles = styled(Link)`
    position: relative;
    text-transform: uppercase;
    font-size: 1.7rem;
    text-decoration: none;
    padding: 0em;
    transition: font-size .25s linear;
    color: var(--white);
    -webkit-font-smoothing: subpixel-antialiased;

    &:not(.logo):hover {
      color: var(--grey);
    }

    &:before {
      transition-property: color, background-color, border-color;
      transition-duration: .2s;
      transition-timing-function: linear;
    }

    &:after {
      transition: all .2s;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0%;
      content: '.';
      color: transparent;
      background: var(--grey);
      height: 1px;
      top: calc(50% + 1.15em);
    }
    &:not(.logo):hover:after {
      width: 100%;
    }
`

const A = styled.a`
   position: relative;
    text-transform: uppercase;
    font-size: 1.7rem;
    text-decoration: none;
    padding: 0em;
    transition: font-size .25s linear;
    color: var(--white);
    -webkit-font-smoothing: subpixel-antialiased;

    &:not(.logo):hover {
      color: var(--grey);
    }

    &:before {
      transition-property: color, background-color, border-color;
      transition-duration: .2s;
      transition-timing-function: linear;
    }

    &:after {
      transition: all .2s;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0%;
      content: '.';
      color: transparent;
      background: var(--grey);
      height: 1px;
      top: calc(50% + 1.15em);
    }
    &:not(.logo):hover:after {
      width: 100%;
    }
`

export default function Nav() {
  return (
    <NavWrapper>
      <NavStyles>
        <NavBrand>
          <LinkStyles className="logo" to="/">
            <Logo />
          </LinkStyles>
        </NavBrand>
        <NavBar>
          <ul>
            <li>
              <LinkStyles to="/#infos">Infos</LinkStyles>
            </li>
            <li>
              <LinkStyles to="/partner">Partnerstädte</LinkStyles>
            </li>
            <li>
              <LinkStyles to="/presse">Presse</LinkStyles>
            </li>
            <li>
              <A target="_blank" href="https://support.kulturgesichternrw.de/">Support Us</A>
            </li>
          </ul>
        </NavBar>
      </NavStyles>
    </NavWrapper>
  );
}
