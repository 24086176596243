import styled from 'styled-components';
import { breakpoints, baseSizes, color } from '../Variables.js';

const { bpMedium, bpLarge, bpXlarge } = breakpoints;

export const SiteBorderStyles = styled.div`

  padding: 5px;
  padding: clamp(5px, 1vw, 25px);
  margin: 0 4%;

  @media (min-width: ${bpMedium}) {
    margin: 0 8%;
  }

  @media (min-width: ${bpLarge}) {
    margin: 0 12%;
  }

  @media (min-width: ${bpXlarge}) {
    margin: 0 18%;
  }
`;