import React from 'react';
import 'normalize.css';
import Nav from './Nav';
import TopBar from './TopBar';
import styled from 'styled-components';

const HeaderStyles = styled.header`
  background-color: var(--white);
  width: 100%;
`;

export default function Header() {
  return (
    <HeaderStyles>
      <Nav />
    </HeaderStyles>
  );
}
