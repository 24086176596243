import React from 'react';
import 'normalize.css';
import styled from 'styled-components';
import Footer from './Footer';
import Header from './Header';
import SectionDonation from './SectionDonation';
import SectionPartner from './SectionPartner';
import SectionVideo from './SectionVideo';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import { Container, Row } from '../styles/common/Layout';
import SectionPartnerCity from './SectionPartnerCity';


if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}


export default function Layout({ children, ...props }) {
  const { bgColor } = props;
  return (
    <>
      <GlobalStyles bgColor={bgColor} />
      <Typography />
      <Header />
      {children}
      <Footer />
    </>
  );
}
