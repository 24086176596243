import React from "react"
import styled from "styled-components"

const VideoWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width:100%;

  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <VideoWrapper>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </VideoWrapper>
)
export default Video