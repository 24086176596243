import React from 'react'
import styled from 'styled-components';
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { SiteBorderStyles } from '../../styles/common/SiteBorderStyles';
import { Link } from 'gatsby';

const TopHeaderStyles = styled.div`
  display: grid;
  padding: 0;
  background-color: var(--mainColor);
`
const TopHeaderWrapperStyles = styled(SiteBorderStyles)`
  padding: 0;
`
const TopBarStyles = styled.div`
  background-color: var(--white);
  display:flex;
  align-items: center;
  position: relative;
  height: inherit;
  justify-content: space-between;
  padding: 0;
  width:100%;
`

const TopBarItemStyles = styled.div`
  height:45px;
  line-height:45px;
  padding:0 1rem;
  cursor: pointer;
  text-align:center;
  flex: 1 0 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

  p, a, h1 {
    font-size: 1.7rem;
    margin: 0;
    color: var(--black);
    text-transform: uppercase;
    font-weight: bold;
  }
`

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  .icon {
    display: flex;
    color:white;
  }

  span {
    color: var(--white);
    font-size: 1.5rem;
    font-weight:400;
    &.text-value {
      margin-left: .6rem;
    }
  }
`

export default function TopBar() {
  return (
    <TopHeaderStyles>
      <TopHeaderWrapperStyles>
        <TopBarStyles>
          <TopBarItemStyles>
              <h1>Shooting: 01. November - Essen, NRW - Informationen &amp; Anmeldung <Link to="/#anmeldung">hier</Link></h1>
          </TopBarItemStyles>
        </TopBarStyles>
      </TopHeaderWrapperStyles>
    </TopHeaderStyles>
  )
}
