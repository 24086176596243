import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';

function BackgroundSection({
  children,
  className,
  imageData,
  backgroundColor,
}) {
  return (
    <BackgroundImage
      Tag="div"
      className={className}
      fluid={imageData}
      backgroundColor={backgroundColor}
      style={{
        width: `100vw`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        padding: `6em 0`,
      }}
    >
      {children}
    </BackgroundImage>
  );
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  height: 100%;
  background-position: center 0%;
  background-repeat: repeat-y;
  background-size: cover;
  opacity: 0.5;
`;

export default StyledBackgroundSection;
