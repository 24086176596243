import React from 'react';
import { useStaticQuery, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Image from './Image.js';
import Video from './Video/Video.js';
import mq from "../styles/media-queries"

const SectionStyles = styled.section`
  background-color: var(--white);
  width: 100%;
  display: flex;
  justify-content: center;
  height: 160px;
  padding: 3em 0;
  ${mq.xs} {
    height: 420px;
    padding: 6em 0;
  }
`;

const ContentStyles = styled.div`
  text-align: center;
  padding: 5px;
  padding: clamp(5px, 1vw, 5px);
  margin-bottom: 2rem;
  margin-top: 1rem;
  margin: 0 18%;
  width: 530px;
  height: 300px;

  h1 {
    margin-bottom: 3rem;
  }
`;

export default function SectionVideo() {

  return (
    <>
      <SectionStyles id="video">
          <ContentStyles>
          <Video
              videoSrcURL="https://www.youtube.com/embed/ma5-tIkz4vM"
              videoTitle="#AlarmstufeRot - Offizieller Clip - #WirvermissendieMusik"
            />
          </ContentStyles>
      </SectionStyles>
    </>
  );
}
