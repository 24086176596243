import styled from 'styled-components';
import mq from "../media-queries"

export const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  ${mq.sm} {
    max-width: 720px;
  }

  ${mq.md} {
    max-width: 960px;
  }
  ${mq.lg} {
    max-width: 1140px;
  }
  ${mq.xl} {
    max-width: 1366px;
  }
  ${mq.xxl} {
    max-width: 1366px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

export const Col = styled.div`
  flex: 0 1 100%;
  max-width: 100%;

  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`

export const Col_2 = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
`

export const Col_4 = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
`

export const Col_8 = styled.div`
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
`

export const Section = styled.section`
  min-height: 500px;
`