import React from 'react';
import { useStaticQuery, StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import BackgroundSection from './BackgroundSection';
import Image from './Image.js';
import mq from "../styles/media-queries"
import { Col, Col_2, Col_4, Container, Row } from '../styles/common/Layout';
import { H1Box } from '../styles/common/Element';

const SectionStyles = styled.section`
  background-color: var(--black);
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 200px;
  padding: 6em 0px;
  overflow: hidden;
`;

const CitiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`

const City = styled(Col)`
  margin-bottom: 30px;

  ${mq.lg} {
    flex: 0 0 30%;
    max-width: 30%;
  }
`
const ColCenter = styled(Col)`
  text-align:center;
`


const StyledLink = styled(Link)`
  font-size: 1.6rem;
  margin: 0 auto;
  text-align:center;
`


export default function SectionPartnerCity() {
  const data = useStaticQuery(graphql`
    query PartnerCityQuery {
      cities: allSanityPartnerCity(limit: 6) {
      nodes {
        _id
        partnerCity
        partnerCityUrl
        partnerCityInstagrammUrl
      }
    }
  }
`);
  const stripTrailingSlash = (str) => {
    const strin = str.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
    return strin.endsWith('/') ?
      strin.slice(0, -1) :
      strin;
  };
  const cities = data.cities.nodes;
  return (
    <>
      <SectionStyles id="partnerCities">
        <Container>
          <Row>
            <Col>
              <H1Box>Partnerstädte </H1Box>
              {cities &&
                <CitiesWrapper>
                  {cities.map(city => (
                    <City key={city._id}>
                      <p>
                        <strong>{city.partnerCity}</strong><br />
                        {city.partnerCityUrl &&
                          <a rel="noreferrer" href={city.partnerCityUrl}>{stripTrailingSlash(city.partnerCityUrl)}</a>
                        }<br/>
                        {city.partnerCityInstagrammUrl &&
                          <a rel="noreferrer" href={city.partnerCityInstagrammUrl}>{stripTrailingSlash(city.partnerCityInstagrammUrl)}</a>
                        }
                        </p>
                    </City>
                  ))}
                </CitiesWrapper>
              }
            </Col>
          </Row>
          <Row>
            <ColCenter>
              <StyledLink to="partner">Mehr Partnerstädte hier</StyledLink>
            </ColCenter>
          </Row>
        </Container>
      </SectionStyles>
    </>
  );
}
