import React from 'react';
import { useStaticQuery, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { breakpoints, baseSizes, color } from '../../styles/Variables.js';

const { bpSmall, bpMedium, bpLarge, bpXlarge } = breakpoints;

const StyledImg = styled(Img)`
  display: block;
`;

const Wrapper = styled.figure`
  margin-left: 0;
  @media (max-width: ${bpMedium}) {
    width: 200px;
    margin: 0 auto;
  }

`;

const WrappedImg = (props) => (
  <Wrapper>
    <StyledImg {...props} />
  </Wrapper>
);

export default function Logo() {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      logo: sanityStoreSettings {
        name
        image {
          asset {
            fluid(maxWidth: 280) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <WrappedImg
        fluid={data.logo.image.asset.fluid}
        alt={data.logo.name}
      />
    </>
  );
}
